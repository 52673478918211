import React from 'react';
import './micarX.css';

const MiCARX = ({ width = '24px', height = '24px' }) => (

  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 198 189.04"
    width={width}
    height={height}
  >
    <defs>
      <style>

      </style>
      <radialGradient id="radial-gradient" cx="-20.18" cy="561.89" fx="-32.65" fy="554.13" r="23.08" gradientTransform="translate(553.49 -389) rotate(-135.11) scale(1 -1.18)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#eef0f9" />
        <stop offset=".48" stop-color="#dae0f2" />
        <stop offset="1" stop-color="#c2cbe8" />
      </radialGradient>
      <radialGradient id="radial-gradient-2" cx="-20.44" cy="544.83" fx="-27.55" fy="544.83" r="17.27" gradientTransform="translate(477.92 -311.51) rotate(-135) scale(1 -1)" gradientUnits="userSpaceOnUse">
        <stop offset=".5" stop-color="#fff" />
        <stop offset=".58" stop-color="#d2d4da" />
        <stop offset=".73" stop-color="#888c9e" />
        <stop offset=".86" stop-color="#515771" />
        <stop offset=".95" stop-color="#2e3654" />
        <stop offset="1" stop-color="#212a4a" />
      </radialGradient>
    </defs>
    <g>
      <path className="clsX-21" d="M84.26,108.77s-2.71-3.3-3.4-4.99c-.82-2.01-1.24-3.66-1.17-5.96.02-.65-.57-4.81.11-7.59,1.24-5.07,2.43-8.06,4.03-10.1,1.2-1.53,6.08-7.36,7.92-8,3.79-1.32,7.23-1.7,10.78-1.08.96.17,4.7.75,8.78,3.23,1.67,1.01,3.14,2.41,3.85,2.93,4.41,3.25,5.29,5.82,5.63,9.71.38,4.3.08,7.03-9.78,19.92-2.59,3.39-6.51,5.07-9.74,6.86-3.47,1.92-13.89-2.07-17.02-4.94h.01Z" />
      <path className="clsX-26" d="M101.66,91.15s-.83-1.02-1.05-1.54c-.25-.62-.38-1.13-.36-1.83,0-.2-.17-1.48.04-2.33.38-1.56.75-2.48,1.24-3.11.37-.47,1.87-2.27,2.44-2.46,1.17-.4,2.22-.52,3.32-.33.3.05,1.45.23,2.7.99.68.41,1.29,1,1.29,1,1.23,1.23,1.97,4.11,1.78,4.98-.38,1.8-1.34,2.76-1.87,3.28-.89.89-.63.45-1.66,1.24-.87.67-4.42,2.23-7.87.12h0Z" />
      <path className="clsX-27" d="M83.64,112.27c-3.42-2.83-6.73-8.5-6.9-12.94-.17-4.44.84-8.82,1.67-13.19.27-1.42.53-2.87,1.16-4.17.5-1.05,4.44-5.94,5.16-6.85.34-.43,4.76-6.21,9.5-6.51,2.04-.13,4.53.09,6.78.08,4.33,0,9.45,2.7,12.05,4.23,1.36.8,4.09,3.66,5.38,4.96,2.93,2.94,7.11,11.03,3.91,17.05-1.92,3.6-7.39,10.72-10.24,13.65-2.66,2.74-5.86,7.71-9.67,8.05-3.68.33-5.42,1.77-10.03,1.07-2.11-.32-6.26-3.34-8.78-5.43h0Z" />
      <path className="clsX-27" d="M83.67,115.04c-1.4-1.63-7.71-6.59-9.05-9.48-1.43-3.08-1.18-6.74-1.27-9.02-.17-4.44.5-5.97,2.15-11.35.46-1.48.67-2.62,2.11-5.16,1.17-2.07,4.41-5.39,5.27-6.17,1.13-1.03,5.69-4.99,9.41-6.84,1.83-.91,4.42-1.33,6.68-1.34,4.33-.01,10.71,2.32,13.29,3.91,3.74,2.3,7.78,5.16,8.97,7.89,1.66,3.81,4.87,6.83,5.11,11.19.2,3.59-3.12,8.3-5.39,11.08-2.27,2.78-8.03,11.56-10.66,14.19s-8.35,7.54-11.85,8.14c-3.01.52-9.28-.66-14.76-7.05h-.01Z" />
      <path className="clsX-27" d="M78.63,114.39c-1.61-1.98-5.83-8.79-7-11.76-1.09-2.77-1.26-6.15-1.35-8.44-.17-4.44.24-5.6.64-8.16.24-1.53,2.42-8.04,4.14-10.4,1.36-1.87,4.28-4.43,7.81-6.76,3.25-2.15,11.9-3.69,14.13-4.04,5.33-.84,13.2,1.32,15.77,2.9,3.74,2.3,5.49,4.84,6.85,5.65,2.7,1.62,9.22,15.71,8.33,18.02-1.79,4.66-8.73,15.43-11.97,18.68-2.94,2.94-6.27,7.43-9.86,9.54-4.27,2.51-7.65,4.22-12.82,4.76-3.2.34-8.57-4.28-14.69-9.99h.02Z" />
    </g>
    <g>
      <circle className="clsX-23" cx="190.87" cy="84.58" r="7.13" />
      <path className="clsX-24" d="M191.2,84.26h3.14v.65h-6.93v-.65h3.79Z" />
    </g>
    <g>
      <circle className="clsX-25" cx="7.13" cy="103.52" r="7.13" />
      <path className="clsX-24" d="M3.67,103.19h3.14v-3.14h.65v3.14h3.14v.65h-3.14v3.14h-.65v-3.14h-3.14s0-.65,0-.65Z" />
    </g>
    <g>
      <path className="clsX-6" d="M104.43,186.11c48.2-2.74,86.44-42.7,86.44-91.59" />
      <polygon className="clsX-22" points="99.13 186.26 106.7 189.04 104.82 186.06 106.48 182.95 99.13 186.26" />
    </g>
    <g>
      <path className="clsX-6" d="M7.13,94.52C7.13,45.63,45.37,5.67,93.57,2.93" />
      <polygon className="clsX-22" points="98.87 2.78 91.52 6.09 93.18 2.98 91.3 0 98.87 2.78" />
    </g>
    <text className="clsX-28" transform="translate(25.89 86.43)"><tspan x="0" y="0">safety switch</tspan></text>
    <text className="clsX-28" transform="translate(85.5 39.47)"><tspan x="0" y="0">CAR</tspan></text>
    <text className="clsX-28" transform="translate(162.96 91.71)"><tspan x="0" y="0">TCR</tspan></text>
    <text className="clsX-28" transform="translate(160.73 116.21)"><tspan x="0" y="0">HLA-I</tspan></text>
    <text className="clsX-28" transform="translate(151.24 138.18)"><tspan x="0" y="0">HLA-II</tspan></text>
    <text className="clsX-28" transform="translate(129.04 159.28)"><tspan x="0" y="0">PD-1</tspan></text>
    <text className="clsX-28" transform="translate(82.02 168.25)"><tspan x="0" y="0">TGFBR2</tspan></text>
    <g>
      <g>
        <path className="clsX-3" d="M155.65,87.82l1.52-1.52h3.56v-1.78c0-.56-.45-1.02-1.02-1.02h-12.44c-.56,0-1.02.45-1.02,1.02v4.06c0,.56.45,1.02,1.02,1.02h8.38v-1.78Z" />
        <g>
          <polyline className="clsX-9" points="159.98 86.3 160.73 86.3 160.73 85.55" />
          <path className="clsX-17" d="M160.59,84c-.18-.3-.5-.49-.87-.49h-12.44c-.56,0-1.02.45-1.02,1.02v4.06c0,.56.45,1.02,1.02,1.02h6.84" />
          <polyline className="clsX-9" points="154.9 89.6 155.65 89.6 155.65 88.85" />
          <polyline className="clsX-18" points="155.77 87.71 157.17 86.3 159.38 86.3" />
        </g>
      </g>
      <g>
        <path className="clsX-3" d="M155.65,91.34l1.52,1.52h3.56v1.78c0,.56-.45,1.02-1.02,1.02h-12.44c-.56,0-1.02-.45-1.02-1.02v-4.06c0-.56.45-1.02,1.02-1.02h8.38v1.78Z" />
        <g>
          <polyline className="clsX-9" points="155.65 90.59 155.65 91.34 156.18 91.87" />
          <line className="clsX-10" x1="157.17" y1="92.86" x2="159.03" y2="92.86" />
          <polyline className="clsX-9" points="159.98 92.86 160.73 92.86 160.73 93.61" />
          <path className="clsX-17" d="M160.59,95.16c-.18.3-.5.49-.87.49h-12.44c-.56,0-1.02-.45-1.02-1.02v-4.06c0-.56.45-1.02,1.02-1.02h6.84" />
          <polyline className="clsX-9" points="154.9 89.56 155.65 89.56 155.65 90.31" />
        </g>
      </g>
      <line className="clsX-16" x1="125.82" y1="86.43" x2="145.97" y2="86.43" />
      <line className="clsX-16" x1="126.33" y1="92.61" x2="146.48" y2="92.61" />
    </g>
    <g>
      <g>
        <path className="clsX-3" d="M155.65,87.82l1.52-1.52h3.56v-1.78c0-.56-.45-1.02-1.02-1.02h-12.44c-.56,0-1.02.45-1.02,1.02v4.06c0,.56.45,1.02,1.02,1.02h8.38v-1.78Z" />
        <g>
          <polyline className="clsX-9" points="159.98 86.3 160.73 86.3 160.73 85.55" />
          <path className="clsX-17" d="M160.59,84c-.18-.3-.5-.49-.87-.49h-12.44c-.56,0-1.02.45-1.02,1.02v4.06c0,.56.45,1.02,1.02,1.02h6.84" />
          <polyline className="clsX-9" points="154.9 89.6 155.65 89.6 155.65 88.85" />
          <polyline className="clsX-18" points="155.77 87.71 157.17 86.3 159.38 86.3" />
        </g>
      </g>
      <g>
        <path className="clsX-3" d="M155.65,91.34l1.52,1.52h3.56v1.78c0,.56-.45,1.02-1.02,1.02h-12.44c-.56,0-1.02-.45-1.02-1.02v-4.06c0-.56.45-1.02,1.02-1.02h8.38v1.78Z" />
        <g>
          <polyline className="clsX-9" points="155.65 90.59 155.65 91.34 156.18 91.87" />
          <line className="clsX-10" x1="157.17" y1="92.86" x2="159.03" y2="92.86" />
          <polyline className="clsX-9" points="159.98 92.86 160.73 92.86 160.73 93.61" />
          <path className="clsX-17" d="M160.59,95.16c-.18.3-.5.49-.87.49h-12.44c-.56,0-1.02-.45-1.02-1.02v-4.06c0-.56.45-1.02,1.02-1.02h6.84" />
          <polyline className="clsX-9" points="154.9 89.56 155.65 89.56 155.65 90.31" />
        </g>
      </g>
      <line className="clsX-16" x1="125.82" y1="86.43" x2="145.97" y2="86.43" />
      <line className="clsX-16" x1="126.33" y1="92.61" x2="146.48" y2="92.61" />
    </g>
    <g>
      <rect className="clsX-11" x="150.54" y="105.85" width="4.76" height="7.93" rx=".79" ry=".79" transform="translate(3.02 223.73) rotate(-72.91)" />
      <rect className="clsX-11" x="148.86" y="111.34" width="4.76" height="7.93" rx=".79" ry=".79" transform="translate(-3.42 225.99) rotate(-72.91)" />
      <rect className="clsX-11" x="142.25" y="103.31" width="4.76" height="7.93" rx=".79" ry=".79" transform="translate(-.4 214) rotate(-72.91)" />
      <rect className="clsX-11" x="140.57" y="108.79" width="4.76" height="7.93" rx=".79" ry=".79" transform="translate(-6.84 216.26) rotate(-72.91)" />
      <line className="clsX-15" x1="140.84" y1="106.11" x2="119.61" y2="99.58" />
    </g>
    <g>
      <rect className="clsX-20" x="140.79" y="128.8" width="4.59" height="7.64" rx=".76" ry=".76" transform="translate(-50.79 154.4) rotate(-49.23)" />
      <rect className="clsX-20" x="137.18" y="132.99" width="4.59" height="7.64" rx=".76" ry=".76" transform="translate(-55.22 153.12) rotate(-49.23)" />
      <rect className="clsX-20" x="134.46" y="123.34" width="4.59" height="7.64" rx=".76" ry=".76" transform="translate(-48.85 147.71) rotate(-49.23)" />
      <rect className="clsX-20" x="130.85" y="127.53" width="4.59" height="7.64" rx=".76" ry=".76" transform="translate(-53.28 146.43) rotate(-49.23)" />
      <line className="clsX-19" x1="133.86" y1="124.67" x2="117.66" y2="110.7" />
      <line className="clsX-19" x1="130.54" y1="128.52" x2="114.33" y2="114.55" />
    </g>
    <g>
      <rect className="clsX-14" x="91.1" y="78.42" width="3.78" height="7.85" rx=".63" ry=".63" />
      <rect className="clsX-8" x="91.1" y="70.57" width="3.78" height="7.85" rx=".63" ry=".63" />
      <rect className="clsX-12" x="91.1" y="62.72" width="3.78" height="7.56" rx=".63" ry=".63" />
      <line className="clsX-7" x1="92.99" y1="59.44" x2="93.1" y2="62.64" />
      <path className="clsX-2" d="M96.04,56.54c0,1.69-1.37,3.05-3.05,3.05s-3.05-1.37-3.05-3.05v-10.46c0-1.69,1.37-3.05,3.05-3.05s3.05,1.37,3.05,3.05v10.46Z" />
    </g>
    <g>
      <polygon className="clsX-4" points="43.97 91.54 48.33 95.85 48.37 103.97 44.34 108.05 35.9 99.7 43.97 91.54" />
      <line className="clsX-13" x1="47.08" y1="100.15" x2="73.32" y2="100.15" />
    </g>
    <g>
      <line className="clsX-19" x1="118.23" y1="141.25" x2="103.47" y2="117.03" />
      <circle className="clsX-5" cx="121.83" cy="147.8" r="6.2" />
    </g>
    <g>
      <line className="clsX-19" x1="94.29" y1="143.47" x2="94.65" y2="118.96" />
      <path className="clsX-1" d="M91.78,150.54s0-.02,0-.03v-6.05c0-.55-.45-1-1-1h-1.58c-.55,0-1,.45-1,1v5.65l-6.48,6.48c-.39.39-.39,1.02,0,1.41l1.12,1.12c.39.39,1.02.39,1.41,0l7.38-7.38c.33-.33.36-.82.14-1.2Z" />
      <path className="clsX-1" d="M95.1,150.62s0-.02,0-.03v-6.05c0-.55-.45-1-1-1h-1.58c-.55,0-1,.45-1,1v5.65l-6.48,6.48c-.39.39-.39,1.02,0,1.41l1.12,1.12c.39.39,1.02.39,1.41,0l7.38-7.38c.33-.33.36-.82.14-1.2Z" />
      <path className="clsX-1" d="M96.8,150.54s0-.02,0-.03v-6.05c0-.55.45-1,1-1h1.58c.55,0,1,.45,1,1v5.65s6.48,6.48,6.48,6.48c.39.39.39,1.02,0,1.41l-1.12,1.12c-.39.39-1.02.39-1.41,0l-7.38-7.38c-.33-.33-.36-.82-.14-1.2Z" />
      <path className="clsX-1" d="M93.48,150.62s0-.02,0-.03v-6.05c0-.55.45-1,1-1h1.58c.55,0,1,.45,1,1v5.65s6.48,6.48,6.48,6.48c.39.39.39,1.02,0,1.41l-1.12,1.12c-.39.39-1.02.39-1.41,0l-7.38-7.38c-.33-.33-.36-.82-.14-1.2Z" />
    </g>
  </svg>

)

export default MiCARX