import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';
import styles from './../style/contact.module.css';
import './../style/leaflet.css';

const Contact = () => {

  const position: LatLngExpression = [46.1688238, 6.1042021];

  const antionLogo = new Icon({
    iconUrl: "logo.png",
    iconSize: [42, 42]
  })

  return (
    <div className={styles.contactContent}>
      <div className={styles.contactTitle}>Our details</div>
      <div className={styles.contactTextContent}>
        <div className={styles.contactText}>
          <div className={styles.contactTextTitle}>Registered address</div>
          <div className={styles.contactTextContentText}>
            Antion Biosciences SA<br />
            Chemin des Aulx 12, CTN12<br />
            1228 Plan-les-Ouates<br />
            Switzerland
          </div>
          <div className={styles.contactTextTitle}>e-mail</div>
          <div className={styles.contactTextContentText}>info[ at ]antionbio.com</div>
          <div className={styles.contactTextTitle}>For investor enquiries</div>
          <div className={styles.contactTextContentText}>investors[ at ]antionbio.com</div>
        </div>
        <div id="map" className="map">
          <MapContainer center={position} zoom={16} scrollWheelZoom={true} style={{borderRadius: '2rem'}}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
              attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>" />
            <Marker position={position} icon={antionLogo}>
              <Popup>
                Antion Biosciences SA
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div >
  )
}

export default Contact;