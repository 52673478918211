import React from 'react';

const InfoIcon = ({ width = '24px', height = '24px', color = 'black' }) => (

  <svg width={width} height={height} fill={color} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <path d="m600 60c-297.61 0-540 242.39-540 540s242.39 540 540 540 540-242.39 540-540-242.39-540-540-540zm0 900c-33.609 0-60-26.391-60-60v-360c0-33.609 26.391-60 60-60s60 26.391 60 60v360c0 33.609-26.391 60-60 60zm0-552c-39.609 0-72-32.391-72-72s32.391-72 72-72 72 32.391 72 72-32.391 72-72 72z" />
  </svg>

)

export default InfoIcon