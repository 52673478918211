import React from 'react';

const SafetyIcon = ({ width = '24px', height = '24px', color = 'black' }) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
  >
    <g>
      <path
        d="m1048.1 466.59c-5.4727 0.003906-10.672-2.3828-14.238-6.5352-3.5664-4.1523-5.1367-9.6562-4.3047-15.066 2.9648-18.672 2.5469-37.727-1.2383-56.25-1.207-6.4844 1.0859-13.129 6.0352-17.492 4.9492-4.3633 11.824-5.8047 18.109-3.793 6.2852 2.0078 11.051 7.1758 12.551 13.598 4.707 22.898 5.2539 46.457 1.6094 69.547-0.66016 4.4414-2.8906 8.5-6.293 11.434-3.3984 2.9375-7.7383 4.5547-12.23 4.5586z"
      />
      <path
        d="m993.58 332.76c-5.5391 0.003907-10.797-2.4414-14.363-6.6758-27.727-32.648-68.488-51.336-111.32-51.039-42.914 1.2891-84.438 15.523-119.12 40.84-5.5469 3.7578-12.676 4.2695-18.703 1.3438-6.0273-2.9219-10.039-8.8398-10.52-15.523-0.48047-6.6836 2.6367-13.113 8.1836-16.871 40.867-29.566 89.734-46.055 140.16-47.289 26.625-0.15625 52.957 5.5195 77.148 16.633 24.195 11.117 45.656 27.398 62.879 47.699 3.0742 3.6641 4.6328 8.3633 4.3594 13.137-0.27734 4.7773-2.3672 9.2656-5.8438 12.551-3.4766 3.2812-8.0781 5.1133-12.859 5.1172z"
      />
      <path
        d="m617.77 839.85c-6.4258 0.007813-12.676-2.1172-17.766-6.043-5.0898-3.9258-8.7344-9.4336-10.359-15.652l-65.828-250.03-29.68 98.926h-0.003906c-2.2344 7.4766-7.3867 13.734-14.293 17.363-6.9062 3.6289-14.988 4.3203-22.41 1.918-7.4219-2.4062-13.562-7.6992-17.035-14.688l-39.488-78.957h-325.91c-6.6992 0-12.887-3.5742-16.238-9.375-3.3477-5.8008-3.3477-12.949 0-18.75 3.3516-5.8008 9.5391-9.375 16.238-9.375h337.5c3.4844 0 6.8984 0.96875 9.8633 2.8008 2.9648 1.8281 5.3594 4.4492 6.918 7.5664l35.102 70.199 35.852-119.51h-0.003906c1.4727-5.375 4.7227-10.098 9.2227-13.391 4.4961-3.2969 9.9766-4.9727 15.547-4.7578 5.5547 0.066406 10.93 1.9609 15.293 5.3945 4.3633 3.4375 7.4727 8.2148 8.8398 13.598l67.836 257.79 70.836-325.8v0.003906c1.6875-7.7539 6.6914-14.375 13.688-18.109 6.9961-3.7383 15.281-4.2109 22.66-1.3008 7.3789 2.9141 13.105 8.918 15.668 16.43l50.082 147.28h42.598c6.6992 0 12.887 3.5703 16.238 9.375 3.3477 5.8008 3.3477 12.945 0 18.75-3.3516 5.8008-9.5391 9.375-16.238 9.375h-55.988c-3.9141-0.003906-7.7266-1.2305-10.906-3.5117-3.1836-2.2812-5.5703-5.4961-6.832-9.2031l-42.281-124.33-70.234 323.08c-1.3828 6.3945-4.8828 12.137-9.9297 16.301-5.0508 4.1602-11.355 6.4961-17.898 6.6328z"
      />
      <path
        d="m618.75 1056.9c-18.707 0.035156-36.656-7.3867-49.875-20.625l-350.62-350.62c-169.29-169.29-144.82-354.71-50.438-455.34 92.773-98.945 270.82-136.69 442.39 14.195 4.8906 4.293 12.211 4.293 17.102 0 171.45-150.77 349.57-113.16 442.39-14.195 94.387 100.63 118.86 286.05-50.457 455.34l-350.62 350.62c-13.215 13.23-31.156 20.652-49.855 20.625zm-248.59-875.4c-32.711-0.21875-65.121 6.2734-95.219 19.082-30.102 12.805-57.254 31.652-79.777 55.375-82.5 87.863-102.04 251.51 49.613 403.12l350.62 350.62c6.1953 6.1953 14.602 9.6758 23.363 9.6758s17.164-3.4805 23.363-9.6758l350.62-350.62c151.65-151.67 132.02-315.32 49.613-403.12-80.852-86.156-237.43-117.7-390.28 16.707l-0.003906-0.003906c-9.2109 8.0781-21.047 12.531-33.297 12.531-12.254 0-24.09-4.4531-33.301-12.531-73.914-64.969-148.61-91.16-215.32-91.16z"
      />
    </g>
  </svg>
)

export default SafetyIcon