import React from 'react';

const EfficacyIcon = ({ width = '24px', height = '24px', color = 'black' }) => (

  <svg
    width={width}
    height={height}
    version="1.1"
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
  >
    <path
      d="m572.66 817.36v160.48l213.02 103.46v-264.87l-122.34-60.988v3.8711c-0.058594 6.6172-3.7266 12.984-10.051 16.102l-80.633 41.938zm90.68-102.11 142.19 70.883 212.03-105.68-102.69-55.871-103.51 47.27c-4.5195 2.0391-11.082 1.7734-15.492-0.36719l-132.54-64.375v108.14zm0-148.07 122.34 59.43v-264.86l-213.02-106.2v139.34l79.84 43.441c6.3789 2.7656 10.844 9.1133 10.844 16.512v112.34zm-126.68-191.88v-148.82c0-7.3984 4.4648-13.75 10.844-16.512l249.45-135.74c5.1719-2.8047 11.609-3.0195 17.148 0l249.44 135.74c6.3789 2.7656 10.844 9.1133 10.844 16.512v304.47c-0.058593 6.6172-3.7266 12.984-10.051 16.102l-110.86 57.715 110.07 59.883c6.3789 2.7656 10.844 9.1133 10.844 16.512v304.48c-0.058593 6.6172-3.7266 12.984-10.051 16.102l-252.24 124.6c-4.5938 2.2539-11.68 2.0273-16.215-0.17188l-247.93-120.42c-6.6055-2.6641-11.281-9.1406-11.281-16.695v-152.95l-135.6 63.879c-5.4297 2.5508-10.473 2.6367-16.242-0.15625l-247.92-120.42c-6.6172-2.6641-11.281-9.1406-11.281-16.695v-307.89c0-7.3984 4.4648-13.75 10.844-16.512l249.44-135.72c5.1719-2.8047 11.609-3.0195 17.148 0zm-375.04 108.61v267.59l213.01 103.46v-264.86l-213.01-106.2zm249.01 108.02v262.87l216.71-106.64v-263.85l-216.71 107.62zm196.29-137.58-212.45-115.58-212 115.36 212.06 105.72zm214.77-90.75v262.84l216.7-106.64v-264.3zm195.79-137.88-211.95-115.33-212.02 115.37 212.02 105.69zm-195.79 592.56v262.86l216.7-106.64v-264.22z"
    />
  </svg>
)

export default EfficacyIcon