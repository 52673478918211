import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import styles from "./../style/footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footerContent}>
      <div className={styles.footerSection}>
        <div className={styles.footerSectionTitle}>FUTURE</div>
        <div className={styles.footerSectionContent}>
          <div>Creating breakthrough therapies. Changing lives.</div>
        </div>
      </div>
      <div className={styles.footerSection}>
        <div className={styles.footerSectionTitle}>TECHNOLOGY</div>
        <div className={styles.footerSectionContent}>
          <div><Link to="/technology#allo">Off-the-shelf therapies</Link></div>
          <div><Link to="/technology#multiplex">Multiplex engineering platform</Link></div>
          <div><Link to="/pipeline">Therapeutic pipeline</Link></div>
          <div><Link to="/technology#publications">Scientific publications</Link></div>
        </div>
      </div>
      <div className={styles.footerSection}>
        <div className={styles.footerSectionTitle}>EXPLORE</div>
        <div className={styles.footerSectionContent}>
          <div><Link to="/news">News</Link></div>
          <div><Link to="/partnering">Partnering</Link></div>
          <div><Link to="/careers">Careers</Link></div>
          <div><Link to="/contact">Contact</Link></div>
        </div>
      </div>
      <div className={styles.footerSection}>
        <div className={styles.footerSectionTitle}>VISIT US</div>
        <div className={styles.footerSectionContent}>
          <div className={styles.visitUsContent}>
            Antion Biosciences SA<br />
            Chemin des Aulx 12, CTN12<br />
            1228 Plan-les-Ouates<br />
            Switzerland
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer