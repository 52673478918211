import React, { useState, useRef, useEffect } from 'react';
import styles from './../style/position.module.css';

const CareerPosition = ({ job }: { job: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [isOpen]);

  return (
    <div className={styles.careerPosition}>
      <div className={styles.careerTitle} onClick={() => setIsOpen(!isOpen)}>
        {job.title}
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      <div className={styles.careerContent} style={{ maxHeight: maxHeight }} ref={contentRef}>
        <div className={styles.careerFeature}>
          <div className={styles.careerHeading}>Summary</div>
          <div className={styles.careerDetail}>
            {job.summary.map((item: string, index: number) =>
              <div key={index}>{item}</div>
            )}
          </div>
        </div>
        <div className={styles.careerFeature}>
          <div className={styles.careerHeading}>Responsibilities</div>
          <div className={styles.careerDetail}>
            {job.responsibilities.map((item: string, index: number) =>
              <div key={index}>- {item}</div>
            )}
          </div>
        </div>
        <div className={styles.careerFeature}>
          <div className={styles.careerHeading}>Reporting</div>
          <div className={styles.careerDetail}>{job.reporting}</div>
        </div>
        <div className={styles.careerFeature}>
          <div className={styles.careerHeading}>Qualifications & Experience</div>
          <div className={styles.careerDetail}>
            {job.qualifications.map((item: string, index: number) =>
              <div key={index}>- {item}</div>
            )}
          </div>
        </div>
        <div className={styles.careerFeature}>
          <div className={styles.careerHeading}>Skills & Abilities</div>
          <div className={styles.careerDetail}>
            {job.skills.map((item: string, index: number) =>
              <div key={index}>- {item}</div>
            )}
          </div>
        </div>
        <div className={styles.careerFeature}>
          <div className={styles.careerHeading}>Contact</div>
          <div className={styles.careerDetail}><a className={styles.noStyle} href={`mailto:${job.contact}`}>{job.contact}</a></div>
        </div>
      </div>
    </div>
  );
};

export default CareerPosition;
