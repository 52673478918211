import React from 'react';

const TunabilityIcon = ({ width = '24px', height = '24px', color = 'black' }) => (

  <svg
    width={width}
    height={height}
    version="1.1"
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
  >
    <g>
      <path d="m201.56 1162.5c-12.914 0-23.438-10.523-23.438-23.438v-328.12c0-12.914 10.523-23.438 23.438-23.438s23.438 10.523 23.438 23.438v328.12c0 12.914-10.523 23.438-23.438 23.438z" />
      <path d="m201.56 553.12c-12.914 0-23.438-10.523-23.438-23.438v-468.75c0-12.914 10.523-23.438 23.438-23.438s23.438 10.523 23.438 23.438v468.75c0 12.914-10.523 23.438-23.438 23.438z" />
      <path
        d="m201.56 834.38c-22.148 0-43.641-4.3398-63.867-12.891-19.523-8.2734-37.078-20.109-52.148-35.156-15.047-15.074-26.883-32.625-35.156-52.148-8.5547-20.227-12.891-41.719-12.891-63.867 0-15.727 2.2266-31.266 6.6094-46.219 2.9062-9.9141 12.141-16.852 22.477-16.852 2.2266 0 4.4531 0.32812 6.6094 0.96094 6 1.7578 10.969 5.7422 13.969 11.25 3 5.4844 3.6797 11.812 1.9219 17.812-3.1172 10.688-4.7109 21.797-4.7109 33.047 0 31.266 12.211 60.699 34.312 82.852 22.172 22.125 51.609 34.312 82.875 34.336 31.289-0.023438 60.703-12.211 82.875-34.336 22.125-22.152 34.312-51.586 34.312-82.852s-12.188-60.703-34.312-82.875c-22.172-22.125-51.609-34.312-82.875-34.312-12.914 0-23.438-10.523-23.438-23.438s10.523-23.438 23.438-23.438c22.148 0 43.641 4.3359 63.867 12.891 19.523 8.2734 37.078 20.109 52.148 35.156 15.047 15.07 26.883 32.625 35.156 52.148 8.5547 20.227 12.891 41.719 12.891 63.867s-4.3359 43.641-12.891 63.867c-8.2734 19.523-20.109 37.074-35.156 52.148-15.07 15.047-32.625 26.883-52.148 35.156-20.227 8.5508-41.719 12.891-63.867 12.891zm-61.453-267.14c-9.0469 0-17.133-5.0625-21.094-13.172-5.6484-11.625-0.79688-25.688 10.828-31.336 3.2344-1.5703 6.6797-2.3672 10.242-2.3672 9.0469 0 17.133 5.0391 21.094 13.172 2.7422 5.625 3.1172 12 1.0781 17.906-2.0391 5.9297-6.2812 10.688-11.906 13.43-3.2344 1.5703-6.6562 2.3672-10.242 2.3672z"
      />
      <path d="m600 1162.5c-12.914 0-23.438-10.523-23.438-23.438v-562.5c0-12.914 10.523-23.438 23.438-23.438s23.438 10.523 23.438 23.438v562.5c0 12.914-10.523 23.438-23.438 23.438z" />
      <path d="m600 318.75c-12.914 0-23.438-10.523-23.438-23.438v-234.38c0-12.914 10.523-23.438 23.438-23.438s23.438 10.523 23.438 23.438v234.38c0 12.914-10.523 23.438-23.438 23.438z" />
      <path
        d="m600 600c-22.148 0-43.641-4.3359-63.867-12.891-19.523-8.2734-37.078-20.109-52.148-35.156-15.047-15.07-26.883-32.625-35.156-52.148-8.5547-20.227-12.891-41.719-12.891-63.867 0-15.703 2.2266-31.266 6.6094-46.219 2.9062-9.9141 12.141-16.852 22.477-16.852 2.2266 0 4.4531 0.32812 6.6094 0.96094 12.398 3.6328 19.523 16.688 15.891 29.086-3.1172 10.664-4.7109 21.773-4.7109 33.023 0 31.266 12.211 60.703 34.312 82.875 22.172 22.102 51.586 34.312 82.875 34.312 31.266 0 60.699-12.211 82.852-34.312 22.125-22.172 34.336-51.609 34.336-82.875s-12.211-60.703-34.336-82.875c-22.152-22.102-51.586-34.312-82.852-34.312-12.914 0-23.438-10.523-23.438-23.438s10.523-23.438 23.438-23.438c22.148 0 43.641 4.3359 63.867 12.891 19.523 8.2734 37.074 20.109 52.148 35.156 15.047 15.07 26.883 32.625 35.156 52.148 8.5508 20.227 12.891 41.719 12.891 63.867s-4.3398 43.641-12.891 63.867c-8.2734 19.523-20.109 37.078-35.156 52.148-15.074 15.047-32.625 26.883-52.148 35.156-20.227 8.5547-41.719 12.891-63.867 12.891zm-61.453-267.14c-9.0469 0-17.133-5.0391-21.094-13.172-5.6484-11.625-0.79688-25.688 10.828-31.336 3.2344-1.5703 6.6797-2.3672 10.242-2.3672 9.0469 0 17.133 5.0391 21.094 13.172 5.6484 11.625 0.79687 25.688-10.828 31.336-3.2344 1.5703-6.6797 2.3672-10.242 2.3672z"
      />
      <path d="m998.44 1162.5c-12.914 0-23.438-10.523-23.438-23.438v-187.5c0-12.914 10.523-23.438 23.438-23.438s23.438 10.523 23.438 23.438v187.5c0 12.914-10.523 23.438-23.438 23.438z" />
      <path d="m998.44 693.75c-12.914 0-23.438-10.523-23.438-23.438v-609.38c0-12.914 10.523-23.438 23.438-23.438s23.438 10.523 23.438 23.438v609.38c0 12.914-10.523 23.438-23.438 23.438z" />
      <path
        d="m998.44 975c-22.148 0-43.641-4.3398-63.867-12.891-19.523-8.2734-37.078-20.109-52.148-35.156-15.074-15.074-26.883-32.625-35.156-52.148-8.5547-20.227-12.891-41.719-12.891-63.867 0-15.727 2.2266-31.266 6.6055-46.219 2.9062-9.9141 12.145-16.852 22.48-16.852 2.2227 0 4.4492 0.32812 6.6055 0.96094 12.398 3.6328 19.523 16.66 15.891 29.059-3.1133 10.691-4.707 21.801-4.707 33.051 0 31.266 12.211 60.699 34.332 82.852 22.148 22.125 51.562 34.316 82.855 34.336 31.266-0.023438 60.699-12.211 82.852-34.336 22.125-22.148 34.316-51.586 34.336-82.852-0.023438-31.266-12.211-60.703-34.336-82.855-22.148-22.121-51.562-34.312-82.828-34.332h-0.023438c-12.914 0-23.438-10.523-23.438-23.438s10.523-23.438 23.438-23.438c22.148 0 43.641 4.3359 63.867 12.891 19.523 8.2734 37.074 20.109 52.148 35.156 15.047 15.07 26.883 32.625 35.156 52.148 8.5508 20.227 12.891 41.719 12.891 63.867s-4.3398 43.641-12.891 63.867c-8.2734 19.523-20.109 37.074-35.156 52.148-15.074 15.047-32.625 26.883-52.148 35.156-20.227 8.5508-41.719 12.891-63.867 12.891zm-61.43-267.14h-0.023437c-9.0469 0-17.137-5.0625-21.094-13.172-5.6484-11.625-0.80078-25.688 10.824-31.359h0.050781c3.2109-1.5703 6.6328-2.3438 10.195-2.3438 9.0469 0 17.137 5.0391 21.094 13.172 5.6484 11.625 0.79687 25.688-10.828 31.336-3.2109 1.5703-6.6562 2.3672-10.219 2.3672z"
      />
    </g>
  </svg>

)

export default TunabilityIcon