import React, { useEffect, useState } from "react";
import styles from "./../style/person.module.css";

const Person = (props: { firstName: string, lastName: string, middleName?: string, title?: string, suffix?: string, addition?: string, position?: string, description?: string[], imageSrc?: string }) => {

  const baseImgUrl = "/people/img/"

  const [name, nameSet] = useState(props.firstName + " " + props.lastName);
  const [isHovered, isHoveredSet] = useState(false);
  const [isModalOpened, isModalOpenedSet] = useState(false);

  useEffect(() => {
    if (props.title) {
      nameSet(props.title + " " + name);
    } else if (props.suffix) {
      nameSet(name + " " + props.suffix);
    }

    const handleEsc = (event: KeyboardEvent) => { if (event.keyCode === 27) handleModalClose(); }

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    }

  }, [name, props.title, props.suffix]);

  const handleMouseEnter = () => { isHoveredSet(true); }
  const handleMouseLeave = () => { isHoveredSet(false); }
  const handleBoxClick = () => isModalOpenedSet(true);
  const handleModalClose = () => isModalOpenedSet(false);

  return (
    <div>
      <div className={styles.personBox} onClick={handleBoxClick}>
        <img className={`${styles.personImage} ${isHovered ? `${styles.greyScale}` : ""}`} src={`${baseImgUrl}${props.imageSrc}`} alt={props.firstName + " " + props.lastName} />
        <div className={styles.personLabel} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div>{props.firstName + " " + props.lastName}</div>
          <div className={styles.personPosition}>{props.position}</div>
          <div>{props.addition}</div>
        </div>
      </div>
      {
        isModalOpened &&
        <div className={styles.modalOverlay} onClick={handleModalClose}>
          <div className={styles.modal}>
            <div className={styles.personBoxModal}>
              <img className={styles.personImageModal} src={`${baseImgUrl}${props.imageSrc}`} alt={props.firstName + " " + props.lastName} />
              <div className={styles.personLabelModal}>
                <div>{props.firstName + " " + props.lastName}</div>
                <div className={styles.personPositionModal}>{props.position}</div>
                <div>{props.addition}</div>
                <div className={styles.personDescription}>
                  {props.description?.map((d: string, i: number) => (
                    <React.Fragment key={i}>
                      <div className={styles.personDescriptionParagraph}>{d}</div>
                    </React.Fragment>
                  ))}
                </div>
                <div className={styles.personClose}>[ Close ]</div>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  )
}

export default Person