import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Navigation from './sections/Navigation';
import Footer from './sections/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Technology from './pages/Technology';
import Partnering from './pages/Partnering';
import News from './pages/News';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import styles from './style/app.module.css';
import Pipeline from './pages/Pipeline';

function App() {
  return (
    <div className={styles.App}>
      <HashRouter>
        <Navigation />
        <div className={styles.content}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/technology" element={<Technology />}></Route>
            <Route path="/Pipeline" element={<Pipeline />}></Route>
            <Route path="/partnering" element={<Partnering />}></Route>
            <Route path="/news" element={<News />}></Route>
            <Route path="/careers" element={<Careers />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
          </Routes>
        </div>
        <Footer />
      </HashRouter >
    </div >
  );
}

export default App;
