import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import styles from './../style/careers.module.css';
import CareerPosition from './../components/CareerPosition'

const Careers = () => {

  const [positions, positionsSet] = useState([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/careers/positions.json')
      .then(response => response.json())
      .then(data => positionsSet(data));
  })

  return (
    <div className={styles.careersContent}>
      {
        positions.length === 0 &&
        <>
          <div className={styles.careersTitle}>Careers</div>
          <div className={styles.careersText}>
            At present, we're not actively expanding our team. Nonetheless, we're consistently on the lookout for talented, driven individuals who could potentially become integral parts of our growth. Please feel free to reach out to us at any time; we value and appreciate the initiative.
          </div>
          <div className={styles.careersContactButton}><Link to="/contact">[ Contact us ]</Link></div>
        </>
      }
      {
        positions.length > 0 &&
        <>
          <div className={styles.careersTitle}>Open positions</div>
          <div className={styles.careersJobs}>
            {positions.map((job, index) => (
              <CareerPosition key={index} job={job} />
            ))}
          </div>
        </>
      }

    </div >
  )
}

export default Careers;