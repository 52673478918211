import { useState } from "react";
import InfoIcon from "../assets/img/InfoIcon";
import styles from "./../style/pipeline.module.css";
import MiCAR19 from "../assets/img/miCAR19";
import MiCAR7 from "../assets/img/miCAR7";
import MiCARX from "../assets/img/miCARX";

const Pipeline = () => {

  const [currentlyExpandedRow, currentlyExpandedRowSet] = useState('');

  const micar19_desc = 'Allogeneic, hypoimmunogenic and safety-enhanced CAR T-cells for treatment of B-cell driven Autoimmune disease';
  const micar7_desc = 'Allogeneic, hypoimmunogenic and fratricide-resistant CAR T-cells for treatment of CD7 positive malignancies';
  const micarX_desc = 'Allogeneic, hypoimmunogenic and potency-enhanced CAR T-cells for treatment of solid tumors';

  const intro = "Differentiated pipeline of therapeutic assets";

  // chimeric antigen receptor (CAR) T-cell solutions


  return (
    <div className={styles.pipelineCcontent}>
      <div className={styles.pipelineTableTitle}>{intro}</div>
      <div className={styles.pipelineSection}>
        <div className={`${styles.section}`}>
          {/* PIPELINE HORIZONTAL */}
          <div className={`${styles.pipelineHorizontal}`}>
            <div className={`${styles.horizontalRow} ${styles.horizontalHeader}`}>
              <div className={`${styles.horizontalCell} ${styles.product}`}>PRODUCT</div>
              <div className={`${styles.horizontalCell} ${styles.indication}`}>INDICATION</div>
              <div className={`${styles.horizontalCell} ${styles.car}`}>TARGET</div>
              <div className={`${styles.horizontalCell} ${styles.desc}`}>DESCRIPTION</div>
              {/* <div className={`${styles.horizontalCell} ${styles.open}`}></div> */}
            </div>
            <div className={`${styles.horizontalRow}`} onClick={() => {
                if (currentlyExpandedRow === 'miCAR19') { currentlyExpandedRowSet('') }
                else currentlyExpandedRowSet('miCAR19');
              }}>
              <div className={`${styles.horizontalCell} ${styles.product}`}>miCAR19</div>
              <div className={`${styles.horizontalCell} ${styles.indication}`}>Autoimmune disease</div>
              <div className={`${styles.horizontalCell} ${styles.car}`}>CD19</div>
              <div className={`${styles.horizontalCell} ${styles.desc}`}>{micar19_desc}</div>
              <div className={`${styles.horizontalCell} ${styles.open}`}><InfoIcon height="24px" width="24px" color="rgba(206, 87, 47, 1)" /></div>
            </div>
            {
              currentlyExpandedRow === "miCAR19" &&
              <div className={styles.expandable}>
                <div className={styles.expandableSlide}>
                  <div className={styles.expandableImage}>
                    <MiCAR19 height="400px" width="400px" />
                  </div>
                  <div className={styles.expandableExplanation}>
                    <div className={styles.expandableExplanationAddition}>multi-gene addition</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Anti-CD19 CAR expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for effective depletion of autoreactive B-cells; low immunogenicity risk with humanized single chain binder (VHH)</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Safety switch expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for precautionary depletion of miCAR19 T-cells</p>
                      </div>
                    </div>
                    <div className={styles.expandableExplanationSilencing}>multi-gene silencing</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TCR silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to facilitate off-the-shelf provision and scaled manufacturing</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Tuned HLA-I silencing, HLA-II silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to circumvent the need for lymphodepletion by evading rejection by host T-cells and NK cells</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>GM-CSF silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to limit the risk of hyperinflammatory immune responses</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={`${styles.horizontalRow} ${styles.horizontalRowOdd}`} onClick={() => {
                if (currentlyExpandedRow === 'miCAR7') { currentlyExpandedRowSet('') }
                else currentlyExpandedRowSet('miCAR7');
              }}>
              <div className={`${styles.horizontalCell} ${styles.product}`}>miCAR7</div>
              <div className={`${styles.horizontalCell} ${styles.indication}`}>
                T-cell & NK cell malignancy<br />
                chemo-resistant AML</div>
              <div className={`${styles.horizontalCell} ${styles.car}`}>CD7</div>
              <div className={`${styles.horizontalCell} ${styles.desc}`}>{micar7_desc}</div>
              <div className={`${styles.horizontalCell} ${styles.open}`}><InfoIcon height="24px" width="24px" color="rgba(206, 87, 47, 1)" /></div>
            </div>
            {
              currentlyExpandedRow === "miCAR7" &&
              <div className={styles.expandable}>
                <div className={styles.expandableSlide}>
                  <div className={styles.expandableImage}>
                    <MiCAR7 height="400px" width="400px" />
                  </div>
                  <div className={styles.expandableExplanation}>
                    <div className={styles.expandableExplanationAddition}>multi-gene addition</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Anti-CD7 CAR expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for effective depletion of CD7 expressing cells; low immunogenicity risk with humanized single chain binder (VHH)</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Safety switch expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for precautionary depletion of miCAR7 T-cells</p>
                      </div>
                    </div>
                    <div className={styles.expandableExplanationSilencing}>multi-gene silencing</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TCR silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to facilitate off-the-shelf provision and scaled manufacturing</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Tuned HLA-I silencing, HLA-II silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to limit immune rejection</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>CD7 silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to make cells fratricide resistance</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={`${styles.horizontalRow}`} onClick={() => {
                if (currentlyExpandedRow === 'miCAR-X') { currentlyExpandedRowSet('') }
                else currentlyExpandedRowSet('miCAR-X');
              }}>
              <div className={`${styles.horizontalCell} ${styles.product}`}>miCAR-X</div>
              <div className={`${styles.horizontalCell} ${styles.indication}`}>Solid tumors</div>
              <div className={`${styles.horizontalCell} ${styles.car}`}>Undisclosed</div>
              <div className={`${styles.horizontalCell} ${styles.desc}`}>{micarX_desc}</div>
              <div className={`${styles.horizontalCell} ${styles.open}`}><InfoIcon height="24px" width="24px" color="rgba(206, 87, 47, 1)" /></div>
            </div>
            {
              currentlyExpandedRow === "miCAR-X" &&
              <div className={styles.expandable}>
                <div className={styles.expandableSlide}>
                  <div className={styles.expandableImage}>
                    <MiCARX height="400px" width="400px" />
                  </div>
                  <div className={styles.expandableExplanation}>
                    <div className={styles.expandableExplanationAddition}>multi-gene addition</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>CAR expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for targeted depletion of tumour cells; low immunogenicity risk with humanized single chain binder (VHH)</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Safety switch expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for precautionary depletion of miCAR-X T-cells</p>
                      </div>
                    </div>
                    <div className={styles.expandableExplanationSilencing}>multi-gene silencing</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TCR silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to facilitate off-the-shelf provision and scaled manufacturing</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Tuned HLA-I silencing, HLA-II silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to limit immune rejection</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>PD-1 silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to prevent T-cell exhaustion</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TGFBR2 silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to enhance cellular potency</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          {/* PIPELINE VERTICAL */}
          <div className={`${styles.pipelineVertical}`}>
            <div className={`${styles.verticalRow}`}>
              <div className={`${styles.verticalCell}`} style={{ backgroundColor: 'rgba(11, 37, 65, 1)' }}>
                <div className={styles.verticalCellTitle} style={{ color: 'white' }}>PRODUCT</div>
                <div className={styles.verticalCellContent} style={{ color: 'white' }}>miCAR19</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>INDICATION</div>
                <div className={styles.verticalCellContent}>Autoimmune disease</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>TARGET</div>
                <div className={styles.verticalCellContent}>CD19</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>DESCRIPTION</div>
                <div className={styles.verticalCellContent}>{micar19_desc}</div>
              </div>
              <div className={`${styles.verticalCell} ${styles.open}`} style={{ backgroundColor: 'rgba(11, 37, 65, 1)' }} onClick={() => {
                if (currentlyExpandedRow === 'miCAR19') { currentlyExpandedRowSet('') }
                else currentlyExpandedRowSet('miCAR19');
              }}><InfoIcon height="24px" width="24px" color="white" /></div>
            </div>
            {
              currentlyExpandedRow === "miCAR19" &&
              <div className={styles.expandable}>
                <div className={styles.expandableSlide}>
                  <div className={styles.expandableImage} style={{ maxWidth: '500px' }}>
                    <MiCAR19 height="100%" width="100%" />
                  </div>
                  <div className={styles.expandableExplanation}>
                    <div className={styles.expandableExplanationAddition}>multi-gene addition</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Anti-CD19 CAR expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for effective depletion of autoreactive B-cells; low immunogenicity risk with humanized single chain binder (VHH)</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Safety switch expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for precautionary depletion of miCAR19 T-cells</p>
                      </div>
                    </div>
                    <div className={styles.expandableExplanationSilencing}>multi-gene silencing</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TCR silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to facilitate off-the-shelf provision and scaled manufacturing</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Tuned HLA-I silencing, HLA-II silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to circumvent the need for lymphodepletion by evading rejection by host T-cells and NK cells</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>GM-CSF silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to limit the risk of hyperinflammatory immune responses</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={`${styles.verticalRow}`} style={{ marginTop: '80px' }}>
              <div className={`${styles.verticalCell}`} style={{ backgroundColor: 'rgba(11, 37, 65, 1)' }}>
                <div className={styles.verticalCellTitle} style={{ color: 'white' }}>PRODUCT</div>
                <div className={styles.verticalCellContent} style={{ color: 'white' }}>miCAR7</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>INDICATION</div>
                <div className={styles.verticalCellContent}>T-cell & NK cell malignancy, chemo-resistant AML</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>TARGET</div>
                <div className={styles.verticalCellContent}>CD7</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>DESCRIPTION</div>
                <div className={styles.verticalCellContent}>{micar7_desc}</div>
              </div>
              <div className={`${styles.verticalCell} ${styles.open}`} style={{ backgroundColor: 'rgba(11, 37, 65, 1)' }} onClick={() => {
                if (currentlyExpandedRow === 'miCAR7') { currentlyExpandedRowSet('') }
                else currentlyExpandedRowSet('miCAR7');
              }}><InfoIcon height="24px" width="24px" color="white" /></div>
            </div>
            {
              currentlyExpandedRow === "miCAR7" &&
              <div className={styles.expandable}>
                <div className={styles.expandableSlide}>
                  <div className={styles.expandableImage}>
                    <MiCAR7 height="100%" width="100%" />
                  </div>
                  <div className={styles.expandableExplanation}>
                    <div className={styles.expandableExplanationAddition}>multi-gene addition</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Anti-CD7 CAR expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for effective depletion of CD7 expressing cells; low immunogenicity risk with humanized single chain binder (VHH)</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Safety switch expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for precautionary depletion of miCAR7 T-cells</p>
                      </div>
                    </div>
                    <div className={styles.expandableExplanationSilencing}>multi-gene silencing</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TCR silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to facilitate off-the-shelf provision and scaled manufacturing</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Tuned HLA-I silencing, HLA-II silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to limit immune rejection</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>CD7 silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to make cells fratricide resistance</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={`${styles.verticalRow}`} style={{ marginTop: '80px' }}>
              <div className={`${styles.verticalCell}`} style={{ backgroundColor: 'rgba(11, 37, 65, 1)' }}>
                <div className={styles.verticalCellTitle} style={{ color: 'white' }}>PRODUCT</div>
                <div className={styles.verticalCellContent} style={{ color: 'white' }}>miCAR-X</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>INDICATION</div>
                <div className={styles.verticalCellContent}>Solid tumors</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>TARGET</div>
                <div className={styles.verticalCellContent}>Undisclosed</div>
              </div>
              <div className={`${styles.verticalCell}`}>
                <div className={styles.verticalCellTitle}>DESCRIPTION</div>
                <div className={styles.verticalCellContent}>{micarX_desc}</div>
              </div>
              <div className={`${styles.verticalCell} ${styles.open}`} style={{ backgroundColor: 'rgba(11, 37, 65, 1)' }} onClick={() => {
                if (currentlyExpandedRow === 'miCAR-X') { currentlyExpandedRowSet('') }
                else currentlyExpandedRowSet('miCAR-X');
              }}><InfoIcon height="24px" width="24px" color="white" /></div>
            </div>
            {
              currentlyExpandedRow === "miCAR-X" &&
              <div className={styles.expandable}>
                <div className={styles.expandableSlide}>
                  <div className={styles.expandableImage}>
                    <MiCARX height="100%" width="100%" />
                  </div>
                  <div className={styles.expandableExplanation}>
                    <div className={styles.expandableExplanationAddition}>multi-gene addition</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>CAR expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for targeted depletion of tumour cells; low immunogenicity risk with humanized single chain binder (VHH)</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Safety switch expression</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>for precautionary depletion of miCAR-X T-cells</p>
                      </div>
                    </div>
                    <div className={styles.expandableExplanationSilencing}>multi-gene silencing</div>
                    <div className={styles.expandableExplanationText}>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TCR silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to facilitate off-the-shelf provision and scaled manufacturing</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>Tuned HLA-I silencing, HLA-II silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to limit immune rejection</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>PD-1 silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to prevent T-cell exhaustion</p>
                      </div>
                      <div>
                        <span className={styles.expandableExplanationTextHighlight}>TGFBR2 silencing</span><br />
                        <p className={styles.expandableExplanationTextDescriptionP}>to enhance cellular potency</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  )
}

export default Pipeline