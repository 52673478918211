import React, { useEffect, useState } from "react";
import Person from "../components/Person";
import styles from "./../style/about.module.css"

interface IPerson {
  firstName: string,
  lastName: string,
  middleName?: string,
  title?: string,
  suffix?: string,
  addition?: string,
  position?: string,
  description?: string[],
  imageSrc?: string
}


const About = () => {


  const [leadership, leadershipSet] = useState<IPerson[]>([]);
  const [board, boardSet] = useState<IPerson[]>([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/people/team.json')
      .then(response => response.json())
      .then((fetchedData: IPerson[]) => {
        boardSet(fetchedData.filter((item: any) => item.type === 'board'));
        leadershipSet(fetchedData.filter((item: any) => item.type === 'leadership'));
      });
  }, [])

  return (
    <div className={styles.aboutUsContent}>
      <div className={styles.historySection}>
        <div className={styles.historySectionTitle}>About us</div>
        <div className={styles.historySectionText}>
          <p>Antion Biosciences SA is a cell and gene engineering biotechnology company based in Geneva (Switzerland), developing off-the-shelf therapies for the treatment of diseases with significant unmet medical needs. Antion’s proprietary technology allows for a modular and tunable approach to multiplex engineering of therapeutic cells. Our goal is to optimize treatment safety and efficacy while ensuring equitable access to these life-transforming therapies for all patients.</p>
        </div>
      </div>
      <div className={styles.peopleSection}>
        <div className={`${styles.leadership} ${styles.peopleTitle}`}>Executive Committee</div>
        <div className={`${styles.leadership} ${styles.peopleList}`}>
          {leadership.map(person => (
            <Person firstName={person.firstName} lastName={person.lastName} imageSrc={person.imageSrc} position={person.position} description={person.description} />
          ))}
        </div>
        <div className={`${styles.board} ${styles.peopleTitle}`}>Board of Directors</div>
        <div className={`${styles.board} ${styles.peopleList}`}>
          {board.map(person => (
            <Person firstName={person.firstName} lastName={person.lastName} imageSrc={person.imageSrc} position={person.position} description={person.description} />
          ))}
        </div>
      </div>
    </div >
  )
}

export default About