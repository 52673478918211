import React, { useEffect, useState } from "react";
import { ReactComponent as PressIcon } from './../assets/icons/press.svg';
import { ReactComponent as ArticleIcon } from './../assets/icons/article.svg';
import { ReactComponent as PosterIcon } from './../assets/icons/poster.svg';
import { ReactComponent as EventIcon } from './../assets/icons/event.svg';
import { ReactComponent as PresentationIcon } from './../assets/icons/presentation.svg';
import styles from './../style/news.module.css';

const News = () => {

  const [news, newsSet] = useState([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/media/news.json')
      .then(response => response.json())
      .then(data => newsSet(data));
  }, [])

  return (
    <div className={styles.newsContent}>
      <div className={styles.newsSection}>
        <div className={styles.newsSectionTitle}>News & Events</div>
        <div className={styles.newsList}>
          <ul className={styles.newsListUl}>
            {
              news.map((item: any, index: number) => (
                <li className={styles.newsItem} key={index}>
                  {item.type === "press" && <PressIcon className={styles.newsIcon} />}
                  {item.type === "article" && <ArticleIcon className={styles.newsIcon} />}
                  {item.type === "poster" && <PosterIcon className={styles.newsIcon} />}
                  {item.type === "event" && <EventIcon className={styles.newsIcon} />}
                  {item.type === "presentation" && <PresentationIcon className={styles.newsIcon} />}
                  <div>
                    <div className={styles.newsDate}>{item.dateOut}</div>
                    <div className={styles.newsInfo}>
                      {item.link ?
                        <div className={styles.newsTitle}><a href={item.link} target="_blank" rel="noreferrer">{item.title}</a></div>
                        :
                        <div className={styles.newsTitle}>{item.title}</div>}
                      {item.description && <div className={styles.newsDescription}>{item.description}</div>}
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div >
  )
}

export default News;