import React from 'react';

const FlexibilityIcon = ({ width = '24px', height = '24px', color = 'black' }) => (

  <svg width={width} height={height} fill={color} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd">
      <path d="m549.01 582.64c-6.0234 69.203-21.898 136.33-46.645 201.6-6.0352 15.926 1.9922 33.77 17.93 39.805 15.938 6.0469 33.77-1.9922 39.805-17.914 17.52-46.199 30.91-93.266 39.898-141.25 8.9883 47.988 22.379 95.051 39.898 141.25 6.0352 15.926 23.867 23.965 39.805 17.914 15.938-6.0352 23.965-23.879 17.93-39.805-24.73-65.23-40.609-132.31-46.633-201.47 36.984-1.8125 73.98-5.6875 110.98-11.699 16.812-2.7344 28.246-18.613 25.523-35.426-2.7344-16.824-18.613-28.262-35.426-25.523-101.45 16.477-202.88 15.84-304.33-0.023438-16.824-2.6406-32.641 8.8906-35.27 25.727-2.6289 16.836 8.9023 32.641 25.727 35.27 36.938 5.7852 73.859 9.6484 110.81 11.543z" />
      <path d="m655.73 440.83c0 30.777-24.949 55.727-55.727 55.727s-55.727-24.949-55.727-55.727c0-30.777 24.949-55.727 55.727-55.727s55.727 24.949 55.727 55.727" />
      <path d="m580.67 139.55c-2.293 26.328-8.3398 51.875-17.746 76.703-2.3047 6.0586 0.75781 12.852 6.8164 15.145 6.0586 2.3047 12.852-0.75781 15.145-6.8164 6.6719-17.578 11.762-35.484 15.18-53.746 3.4336 18.266 8.5195 36.168 15.18 53.746 2.3047 6.0586 9.0977 9.1211 15.156 6.8164 6.0586-2.293 9.1211-9.0859 6.8164-15.145-9.4062-24.816-15.445-50.352-17.734-76.656 14.062-0.69531 28.141-2.1719 42.215-4.4531 6.4062-1.043 10.754-7.0781 9.7188-13.488-1.043-6.3945-7.0781-10.754-13.488-9.707-38.594 6.2773-77.195 6.0234-115.79-0.011718-6.4062-0.99609-12.422 3.3828-13.43 9.793-0.99609 6.4062 3.3828 12.422 9.793 13.43 14.051 2.1953 28.105 3.6602 42.168 4.3906z" />
      <path d="m621.27 85.586c0 11.707-9.4961 21.203-21.207 21.203-11.711 0-21.203-9.4961-21.203-21.203 0-11.711 9.4922-21.207 21.203-21.207 11.711 0 21.207 9.4961 21.207 21.207" />
      <path d="m989.26 353c-23.953 11.172-49.094 18.719-75.312 22.98-6.3945 1.043-10.754 7.0781-9.707 13.477 1.043 6.4062 7.0781 10.754 13.477 9.707 18.562-3.0117 36.613-7.5469 54.145-13.715-14.102 12.086-27.07 25.453-38.965 40.02-4.0938 5.0156-3.3477 12.422 1.668 16.523 5.0273 4.1055 12.434 3.3477 16.535-1.668 16.789-20.555 35.879-38.555 57.516-53.699 7.6328 11.844 15.949 23.305 24.973 34.344 4.0938 5.0273 11.508 5.7734 16.523 1.668 5.0273-4.0938 5.7734-11.496 1.668-16.523-24.73-30.289-43.812-63.84-57.887-100.29-2.3281-6.0469-9.1445-9.0703-15.191-6.7305-6.0469 2.3398-9.0586 9.1445-6.7188 15.191 5.125 13.273 10.871 26.172 17.281 38.711z" />
      <path d="m1064 332.22c5.8555 10.141 2.375 23.113-7.7656 28.969-10.129 5.8438-23.113 2.375-28.957-7.7656-5.8555-10.141-2.375-23.113 7.7656-28.969 10.129-5.8438 23.113-2.3633 28.957 7.7656z" />
      <path d="m318.84 965.27c18.684-18.695 39.73-34.379 62.902-47.352 5.6523-3.168 7.6797-10.332 4.5117-15.996-3.168-5.6523-10.332-7.6797-15.996-4.5117-16.402 9.1914-31.812 19.633-46.176 31.414 9.1211-16.188 16.727-33.18 22.922-50.93 2.1367-6.1211-1.0938-12.828-7.2109-14.965-6.1328-2.1367-12.828 1.1055-14.965 7.2227-8.7461 25.055-20.531 48.492-35.688 70.129-11.219-8.5195-22.957-16.441-35.207-23.734-5.5664-3.3125-12.781-1.4766-16.105 4.0938-3.3125 5.5664-1.4883 12.781 4.0781 16.105 33.602 20.004 63.012 45 88.703 74.438 4.2617 4.8828 11.688 5.3867 16.57 1.1289 4.8828-4.2617 5.3867-11.688 1.1289-16.57-9.3477-10.715-19.164-20.879-29.473-30.469z" />
      <path d="m255.66 1010.4c-8.9648-7.5234-10.129-20.914-2.6055-29.879 7.5117-8.9648 20.902-10.129 29.867-2.6055 8.9648 7.5117 10.129 20.902 2.6172 29.867-7.5234 8.9648-20.914 10.141-29.879 2.6172z" />
      <path d="m143.11 539.26c25.535 6.8281 49.645 17.219 72.469 30.805 5.5664 3.3125 12.781 1.4766 16.105-4.0938 3.3125-5.5664 1.4766-12.793-4.0938-16.105-16.152-9.6133-32.902-17.734-50.293-24.277 18.574-0.20313 37.105-2.1133 55.57-5.6172 6.3594-1.2109 10.547-7.3672 9.3359-13.738-1.1992-6.3711-7.3555-10.559-13.727-9.3477-26.074 4.957-52.262 6.4688-78.574 4.1641 1.7656-13.98 2.7617-28.105 2.9531-42.359 0.085937-6.4805-5.1133-11.82-11.594-11.902-6.4805-0.085937-11.82 5.1016-11.902 11.594-0.52734 39.098-7.4766 77.062-20.125 114.04-2.1016 6.1328 1.1758 12.816 7.3203 14.914 6.1328 2.1016 12.816-1.1758 14.914-7.3086 4.6094-13.465 8.4961-27.047 11.641-40.766z" />
      <path d="m72.469 507.1c2.0273-11.52 13.031-19.223 24.562-17.195 11.52 2.0273 19.223 13.031 17.195 24.562-2.0273 11.52-13.031 19.223-24.562 17.195-11.52-2.0273-19.223-13.031-17.195-24.562z" />
      <path d="m424.32 173.93c6.8516 25.523 9.9102 51.602 9.5508 78.156-0.085938 6.4805 5.1133 11.809 11.594 11.902 6.4805 0.085938 11.82-5.1133 11.902-11.594 0.25391-18.805-1.0938-37.367-4.1172-55.703 9.457 15.996 20.363 31.078 32.641 45.312 4.2344 4.9219 11.664 5.4727 16.57 1.2344 4.9062-4.2344 5.4609-11.664 1.2227-16.57-17.328-20.102-31.727-42.023-42.887-65.965 12.984-5.4609 25.715-11.664 38.16-18.625 5.6523-3.168 7.6797-10.332 4.5117-15.996-3.168-5.6523-10.332-7.6797-15.984-4.5117-34.129 19.094-70.488 32.062-108.82 39.602-6.3711 1.2461-10.523 7.4297-9.2656 13.789 1.2461 6.3594 7.4297 10.512 13.789 9.2656 13.957-2.7461 27.66-6.168 41.125-10.297z" />
      <path d="m416.84 96.66c10.992-3.9961 23.172 1.6797 27.168 12.684 4.0078 10.992-1.668 23.172-12.672 27.168-10.992 4.0078-23.172-1.668-27.168-12.672-4.0078-10.992 1.668-23.172 12.672-27.18z" />
      <path d="m910.95 940.32c-15.168-21.648-26.953-45.098-35.711-70.176-2.1367-6.1211-8.832-9.3477-14.953-7.2109-6.1211 2.1367-9.3594 8.832-7.2227 14.965 6.1914 17.746 13.801 34.738 22.922 50.93-14.363-11.797-29.773-22.234-46.176-31.414-5.6641-3.168-12.828-1.1523-15.996 4.5117-3.1562 5.6523-1.1406 12.816 4.5234 15.984 23.16 12.973 44.195 28.645 62.855 47.316-10.332 9.5742-20.16 19.766-29.484 30.562-4.2227 4.9062-3.6836 12.324 1.2227 16.559 4.9219 4.2344 12.336 3.6836 16.57-1.2227 25.535-29.617 55.262-54.238 88.715-74.426 5.543-3.3477 7.332-10.57 3.9844-16.129-3.3477-5.543-10.57-7.332-16.129-3.9844-12.18 7.3438-23.891 15.254-35.125 23.734z" />
      <path d="m944.4 1010.4c-8.9648 7.5234-22.355 6.3477-29.867-2.6172-7.5234-8.9648-6.3594-22.344 2.6055-29.867 8.9648-7.5234 22.355-6.3477 29.879 2.6172s6.3477 22.344-2.6172 29.867z" />
      <path d="m289.23 259.81c15.18 21.648 26.965 45.098 35.711 70.176 2.1367 6.1211 8.8438 9.3594 14.965 7.2109 6.1211-2.1367 9.3594-8.832 7.2227-14.953-6.1914-17.746-13.801-34.754-22.922-50.93 14.352 11.785 29.773 22.223 46.176 31.402 5.6523 3.168 12.816 1.1523 15.984-4.5117 3.168-5.6523 1.1523-12.816-4.5117-15.984-23.16-12.961-44.195-28.633-62.867-47.316 10.344-9.5742 20.172-19.754 29.484-30.551 4.2344-4.9219 3.6836-12.336-1.2227-16.57s-12.336-3.6836-16.559 1.2227c-25.535 29.617-55.273 54.238-88.715 74.426-5.5547 3.3477-7.3438 10.57-3.9844 16.129 3.3477 5.5547 10.57 7.332 16.129 3.9844 12.168-7.3438 23.879-15.254 35.113-23.734z" />
      <path d="m255.79 189.77c8.9648-7.5234 22.344-6.3477 29.867 2.6172 7.5234 8.9648 6.3477 22.344-2.6172 29.867-8.9648 7.5234-22.344 6.3477-29.867-2.6172-7.5234-8.9648-6.3477-22.344 2.6172-29.867z" />
      <path d="m739.38 160.75c-11.16 23.965-25.57 45.898-42.91 66.012-4.2344 4.9062-3.6836 12.336 1.2227 16.57 4.9062 4.2344 12.336 3.6836 16.57-1.2227 12.277-14.242 23.184-29.328 32.641-45.312-3.0234 18.324-4.3672 36.887-4.1172 55.691 0.097656 6.4805 5.4258 11.676 11.902 11.594 6.4805-0.085938 11.676-5.4258 11.594-11.902-0.34766-26.543 2.6992-52.598 9.5391-78.109 13.465 4.1641 27.203 7.5977 41.207 10.262 6.3711 1.2109 12.527-2.9766 13.738-9.3477 1.2109-6.3594-2.9766-12.516-9.3477-13.727-38.41-7.3086-74.605-20.746-108.82-39.625-5.6758-3.1328-12.828-1.0547-15.961 4.6211-3.1328 5.6758-1.0547 12.828 4.6211 15.961 12.445 6.8633 25.152 13.055 38.113 18.539z" />
      <path d="m783.31 96.77c11.004 3.9961 16.68 16.176 12.684 27.168-4.0078 11.004-16.188 16.68-27.18 12.672-11.004-3.9961-16.68-16.176-12.672-27.168 3.9961-11.004 16.176-16.68 27.168-12.672z" />
      <path d="m619.39 1060.5c2.293-26.328 8.3281-51.875 17.746-76.703 2.293-6.0586-0.75781-12.852-6.8164-15.145-6.0703-2.3047-12.852 0.75781-15.156 6.8164-6.6602 17.578-11.762 35.484-15.18 53.746-3.4219-18.266-8.5078-36.168-15.18-53.746-2.293-6.0586-9.0859-9.1211-15.145-6.8164-6.0586 2.293-9.1211 9.0859-6.8281 15.145 9.4219 24.816 15.457 50.352 17.746 76.656-14.074 0.69531-28.152 2.1719-42.227 4.4531-6.3945 1.043-10.754 7.0781-9.707 13.488 1.043 6.3945 7.0781 10.754 13.477 9.707 38.605-6.2773 77.195-6.0234 115.8 0.011718 6.4062 0.99609 12.422-3.3828 13.414-9.793 1.0078-6.4062-3.3828-12.422-9.793-13.43-14.051-2.1953-28.105-3.6602-42.156-4.3906z" />
      <path d="m621.19 1114.4c0 11.711-9.4922 21.207-21.203 21.207-11.711 0-21.203-9.4961-21.203-21.207 0-11.707 9.4922-21.203 21.203-21.203 11.711 0 21.203 9.4961 21.203 21.203" />
      <path d="m210.96 847.14c23.953-11.184 49.094-18.719 75.301-22.992 6.3945-1.0312 10.754-7.0781 9.707-13.477-1.043-6.3945-7.0781-10.754-13.477-9.707-18.551 3.0117-36.613 7.5586-54.133 13.727 14.102-12.098 27.059-25.465 38.953-40.02 4.1055-5.0273 3.3477-12.434-1.668-16.535-5.0273-4.0938-12.434-3.3477-16.535 1.668-16.789 20.566-35.867 38.555-57.516 53.699-7.6328-11.844-15.949-23.293-24.961-34.344-4.1055-5.0156-11.508-5.7734-16.535-1.668-5.0156 4.1055-5.7734 11.508-1.668 16.523 24.73 30.301 43.812 63.852 57.887 100.3 2.3398 6.0469 9.1445 9.0586 15.191 6.7188 6.0469-2.3281 9.0586-9.1328 6.7188-15.18-5.1133-13.273-10.871-26.184-17.27-38.711z" />
      <path d="m136.16 867.91c-5.8555-10.129-2.375-23.113 7.7656-28.957 10.129-5.8555 23.113-2.375 28.969 7.7539 5.8438 10.141 2.3633 23.113-7.7656 28.969-10.141 5.8555-23.113 2.375-28.969-7.7656z" />
      <path d="m191.61 386.62c21.66 15.156 40.754 33.156 57.551 53.723 4.1055 5.0273 11.508 5.7734 16.535 1.668 5.0156-4.0938 5.7734-11.508 1.668-16.523-11.891-14.555-24.852-27.926-38.965-40.02 17.531 6.168 35.578 10.703 54.145 13.727 6.3945 1.043 12.434-3.3125 13.477-9.707 1.043-6.4062-3.3125-12.445-9.707-13.488-26.195-4.2617-51.324-11.785-75.266-22.957 6.4453-12.539 12.203-25.465 17.258-38.797 2.3047-6.0586-0.74219-12.852-6.8164-15.156-6.0586-2.293-12.852 0.75781-15.145 6.8281-13.871 36.551-33.383 69.863-57.91 100.27-4.0664 5.0508-3.2773 12.445 1.7773 16.523 5.0391 4.0664 12.445 3.2773 16.512-1.7773 8.9297-11.062 17.23-22.5 24.887-34.32z" />
      <path d="m136.21 332.24c5.8555-10.141 18.828-13.621 28.969-7.7656 10.129 5.8438 13.609 18.828 7.7539 28.957-5.8438 10.141-18.828 13.621-28.957 7.7656-10.141-5.8438-13.621-18.828-7.7656-28.957z" />
      <path d="m1056.9 660.86c-25.535-6.8281-49.645-17.219-72.469-30.805-5.5664-3.3125-12.781-1.4766-16.105 4.0938-3.3125 5.5664-1.4766 12.781 4.0938 16.105 16.152 9.6133 32.902 17.734 50.293 24.277-18.574 0.20312-37.105 2.1133-55.57 5.6172-6.3594 1.2109-10.547 7.3672-9.3359 13.738 1.1992 6.3711 7.3555 10.559 13.727 9.3477 26.074-4.957 52.262-6.4805 78.574-4.1641-1.7656 13.98-2.7617 28.094-2.9531 42.359-0.085937 6.4805 5.1133 11.809 11.594 11.902 6.4805 0.085937 11.82-5.1133 11.902-11.594 0.52734-39.098 7.4766-77.074 20.125-114.04 2.1016-6.1328-1.1758-12.816-7.3203-14.914-6.1328-2.1016-12.816 1.1758-14.914 7.3086-4.6094 13.465-8.4961 27.047-11.641 40.766z" />
      <path d="m1127.5 693.02c-2.0273 11.52-13.031 19.223-24.562 17.195-11.52-2.0273-19.223-13.043-17.195-24.562 2.0273-11.52 13.031-19.234 24.562-17.195 11.52 2.0273 19.223 13.031 17.195 24.562z" />
      <path d="m881.38 234.83c-18.684 18.707-39.73 34.391-62.902 47.363-5.6641 3.1562-7.6797 10.32-4.5117 15.984 3.1562 5.6523 10.32 7.6797 15.984 4.5117 16.402-9.1797 31.812-19.621 46.176-31.414-9.1211 16.188-16.727 33.18-22.922 50.93-2.1367 6.1211 1.1055 12.828 7.2227 14.965 6.1211 2.1367 12.828-1.0938 14.965-7.2227 8.7344-25.055 20.52-48.492 35.676-70.129 11.219 8.5195 22.957 16.441 35.207 23.734 5.5664 3.3125 12.793 1.4883 16.105-4.0781 3.3242-5.5781 1.4883-12.793-4.0781-16.105-33.602-20.016-63.012-45.012-88.703-74.449-4.2617-4.8828-11.688-5.3867-16.57-1.1289-4.8828 4.2734-5.3867 11.699-1.1289 16.586 9.3594 10.715 19.176 20.867 29.484 30.457z" />
      <path d="m944.54 189.73c8.9648 7.5234 10.141 20.902 2.6172 29.867-7.5234 8.9648-20.914 10.141-29.879 2.6172-8.9648-7.5234-10.129-20.914-2.6055-29.879 7.5234-8.9648 20.902-10.129 29.867-2.6055z" />
      <path d="m460.63 1039.4c11.16-23.953 25.57-45.887 42.91-66.012 4.2344-4.9062 3.6836-12.324-1.2227-16.559-4.9219-4.2344-12.336-3.6836-16.57 1.2227-12.277 14.242-23.184 29.328-32.652 45.312 3.0352-18.324 4.3672-36.898 4.1172-55.691-0.085938-6.4805-5.4102-11.676-11.891-11.594-6.4922 0.085938-11.676 5.4258-11.594 11.902 0.34766 26.543-2.6992 52.598-9.5508 78.098-13.453-4.1523-27.191-7.5859-41.195-10.246-6.3711-1.2109-12.527 2.9766-13.738 9.3359-1.2109 6.3711 2.9766 12.527 9.3477 13.738 38.41 7.3086 74.605 20.746 108.8 39.613 5.6758 3.1328 12.828 1.0664 15.961-4.6094 3.1328-5.6758 1.0664-12.828-4.6094-15.961-12.457-6.8633-25.152-13.055-38.113-18.551z" />
      <path d="m416.69 1103.4c-10.992-3.9961-16.668-16.176-12.672-27.18 4.0078-10.992 16.188-16.668 27.18-12.672 10.992 4.0078 16.68 16.188 12.672 27.18-4.0078 10.992-16.176 16.68-27.18 12.672z" />
      <path d="m149.81 699.12c26.328-2.3164 52.523-0.80469 78.625 4.1641 6.3594 1.1992 12.516-2.9883 13.727-9.3477 1.2109-6.3711-2.9766-12.527-9.3477-13.738-18.469-3.5039-36.984-5.4102-55.559-5.6172 17.387-6.5391 34.141-14.664 50.293-24.277 5.5664-3.3242 7.4023-10.535 4.0781-16.105-3.3125-5.5781-10.523-7.4023-16.105-4.0938-22.801 13.586-46.895 23.965-72.406 30.793-3.1211-13.738-7.0195-27.348-11.711-40.812-2.1367-6.1211-8.8438-9.3594-14.965-7.2227s-9.3594 8.832-7.2227 14.953c12.875 36.926 19.344 74.988 20.102 114.05 0.12109 6.4805 5.4844 11.641 11.965 11.52 6.4922-0.13281 11.652-5.4961 11.52-11.977-0.27734-14.219-1.2617-28.32-2.9883-42.289z" />
      <path d="m72.422 693.07c-2.0391-11.531 5.6641-22.535 17.195-24.562 11.52-2.0391 22.523 5.6641 24.562 17.195 2.0273 11.52-5.6758 22.523-17.195 24.562-11.531 2.0273-22.535-5.6758-24.562-17.195z" />
      <path d="m1008.6 813.56c-21.66-15.156-40.766-33.156-57.562-53.734-4.0938-5.0156-11.496-5.7617-16.523-1.668-5.0156 4.1055-5.7734 11.508-1.668 16.535 11.891 14.555 24.852 27.926 38.953 40.02-17.52-6.168-35.578-10.715-54.133-13.727-6.3945-1.043-12.445 3.3125-13.477 9.707-1.043 6.3945 3.3008 12.445 9.707 13.477 26.195 4.2734 51.324 11.797 75.254 22.969-6.4336 12.527-12.191 25.465-17.258 38.797-2.293 6.0586 0.75781 12.852 6.8164 15.145 6.0703 2.3047 12.852-0.74219 15.156-6.8164 13.871-36.562 33.383-69.863 57.91-100.27 4.0664-5.0508 3.2773-12.445-1.7773-16.523-5.0391-4.0664-12.445-3.2773-16.512 1.7773-8.9297 11.062-17.23 22.5-24.887 34.32z" />
      <path d="m1064 867.94c-5.8555 10.141-18.828 13.621-28.969 7.7656-10.129-5.8555-13.609-18.828-7.7656-28.969 5.8555-10.129 18.828-13.609 28.969-7.7539 10.141 5.8438 13.609 18.828 7.7656 28.957z" />
      <path d="m775.67 1026.1c-6.8516-25.523-9.9102-51.59-9.5625-78.145 0.097656-6.4922-5.1016-11.82-11.578-11.902-6.4922-0.085938-11.82 5.1016-11.902 11.594-0.25391 18.793 1.0781 37.367 4.1172 55.691-9.457-15.984-20.375-31.066-32.652-45.312-4.2344-4.9062-11.652-5.4609-16.559-1.2227-4.9219 4.2227-5.4609 11.652-1.2344 16.559 17.328 20.113 31.738 42.023 42.898 65.965-12.996 5.4727-25.715 11.664-38.16 18.637-5.6641 3.168-7.6797 10.332-4.5117 15.984 3.1562 5.6641 10.32 7.6797 15.984 4.5117 34.129-19.094 70.477-32.062 108.82-39.59 6.3594-1.2461 10.512-7.4297 9.2656-13.789-1.2461-6.3711-7.4297-10.523-13.789-9.2656-13.957 2.7344-27.672 6.1562-41.125 10.285z" />
      <path d="m783.14 1103.4c-11.004 3.9961-23.172-1.6797-27.18-12.672-3.9961-11.004 1.6797-23.172 12.672-27.18 11.004-3.9961 23.172 1.6797 27.18 12.672 3.9961 11.004-1.6797 23.172-12.672 27.18z" />
      <path d="m1050.2 501c-26.328 2.3164-52.523 0.79297-78.625-4.1641-6.3594-1.2109-12.516 2.9766-13.727 9.3477-1.2109 6.3711 2.9766 12.527 9.3477 13.738 18.469 3.5039 36.984 5.4102 55.559 5.6172-17.387 6.5391-34.141 14.664-50.293 24.277-5.5664 3.3242-7.4023 10.535-4.0781 16.105 3.3125 5.5664 10.523 7.4023 16.105 4.0938 22.801-13.586 46.895-23.965 72.406-30.793 3.1211 13.738 7.0195 27.348 11.711 40.812 2.1367 6.1211 8.8438 9.3594 14.965 7.2227 6.1211-2.1367 9.3594-8.832 7.2227-14.965-12.875-36.926-19.344-74.977-20.102-114.04-0.12109-6.4805-5.4844-11.641-11.965-11.52-6.4922 0.13281-11.652 5.4961-11.52 11.977 0.27734 14.219 1.2617 28.32 2.9883 42.289z" />
      <path d="m1127.6 507.05c2.0391 11.531-5.6641 22.535-17.195 24.562-11.52 2.0391-22.523-5.6758-24.562-17.195-2.0273-11.52 5.6758-22.535 17.195-24.562 11.531-2.0273 22.535 5.6758 24.562 17.195z" />
    </g>
  </svg>


)

export default FlexibilityIcon