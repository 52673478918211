import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './../assets/img/logo.svg'
import styles from './../style/navigation.module.css';

const Navigation = () => {

  const location = useLocation();

  const [windowSize, windowSizeSet] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [menuOpened, menuOpenedSet] = useState(false);

  const handleMenuOpen = () => {
    menuOpenedSet(!menuOpened);
  }

  const closeDropdownMenu = () => {
    menuOpenedSet(false);
  }

  useEffect(() => {
    const handleResize = () => {
      windowSizeSet({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    if (windowSize.width > 1500 && menuOpened) {
      menuOpenedSet(false);
    }
  }, [windowSize.width, menuOpened])

  useEffect(() => {
    if (menuOpened) document.documentElement.style.overflow = "hidden";
    else document.documentElement.style.overflow = "auto";
  }, [menuOpened])

  const links = [
    { url: "/technology", title: "Technology" },
    { url: "/pipeline", title: "Pipeline" },
    { url: "/partnering", title: "Partnering" },
    { url: "/news", title: "News" },
    { url: "/about", title: "About" },
    { url: "/careers", title: "Careers" },
    { url: "/contact", title: "Contact" }
  ];

  return (
    <div id="nav-container" className={styles.navContainer}>
      <div className={styles.navBarLogo}><Link className={styles.navBarLogo} to="/"><img src={logo} alt="Antion Logo" /></Link></div>
      <div className={styles.horizontalNavBar}>
        <ul>
          {links.map(link => (
            <li><Link
              to={link.url}
              key={link.url}
              style={{
                backgroundColor: location.pathname === link.url ? "rgba(11, 37, 65, 1)" : "transparent",
                color: location.pathname === link.url ? "white" : "rgba(11, 37, 65, 1)",
              }}
            >
              {link.title}
            </Link></li>
          ))}
        </ul>
      </div>
      {
        !menuOpened &&
        <svg className={styles.menuOpenSvg} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60" viewBox="0 0 30 30" onClick={handleMenuOpen}>
          <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"></path>
        </svg>
      }
      {
        menuOpened &&
        <div className={styles.dropdownNavBar} onClick={closeDropdownMenu}>
          <svg className={styles.menuCloseSvg} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60" viewBox="0 0 50 50" onClick={handleMenuOpen}>
            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
          </svg>
          <div className={styles.dropdownMenuContent}>
            <ul>
              {links.map(link => (
                <li><Link
                  to={link.url}
                  key={link.url}
                >
                  {link.title}
                </Link></li>
              ))}
            </ul>
          </div>
        </div>
      }
    </div>
  );
}

export default Navigation;
